const USER_PERMISSION = {
  COMMON: 'COMMON',
  ADMIN: 'ADMIN',
  MASTER: 'MASTER',
}

const permissionName = {
  [USER_PERMISSION.COMMON]: 'Normal',
  [USER_PERMISSION.ADMIN]: 'Administrador',
  [USER_PERMISSION.MASTER]: 'Master',
}

const translatePermission = (key) => {
  return permissionName[key] || key
}

export {
  USER_PERMISSION,
  translatePermission,
}
