import styled from 'styled-components'

export default styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  .container-data {
    width: 80vw;
    height: 100%;
  }
`
