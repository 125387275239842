import React from 'react'
import _ from 'lodash'

import CertificateRow from 'ui/components/ContainerCommonPanels/CertificateRow'

const processIdentifierFields = {
  number: 'Número do Processo',
  journal: 'Diário',
  involved_type: 'Envolvimento',
  start_date: 'Data de início',
  end_date: 'Data de termino',
  moves: {
    name: 'Movimentações',
    fields: {
      kind: 'Tipo',
      section: 'Seção',
      date: 'Data da movimentação',
    },
  },
}

const infos = [
  {
    serviceId: 'stf_process_name',
    options: {
      fields: {
        child_process: {
          name: 'Processos',
          fields: {
            process_number: 'Numero',
            assessment_date: 'Data de autuação',
            process_type: 'Tipo do processo',
            publicy: 'Publicidade do processo',
            pending: 'Em trâmite',
            applicants: {
              name: 'Partes',
              fields: {
                name: 'Nome da parte',
                kind: 'Tipo da parte',
              },
            },
          },
        },
      },
    },
  },
  {
    serviceId: 'stj_process_name',
    options: {
      fields: {
        child_process: {
          name: 'Processos',
          fields: {
            process_uf: 'Processo + UF',
            register_number: 'Número de registro',
            assessment_date: 'Data de autuação',
            process_type: 'Tipo do processo',
            rapporteur: 'Relator',
            branch_of_law: 'Ramo do direito',
            court_of_origin: 'Tribunal de origem',
            'last_stage.description': 'Descrição da fase',
            'last_stage.date': 'Data da fase',
            applicants: {
              name: 'Partes',
              fields: {
                name: 'Nome da parte',
                type: 'Tipo da parte',
              },
            },
          },
        },
      },
    },
  },
  {
    serviceId: 'processes_classifier',
    options: {
      fields: {
        unclassified_processes: {
          name: 'Processos não classificados',
          fields: processIdentifierFields,
        },
        whitelist_processes: {
          name: 'Processos na Whitelist',
          fields: processIdentifierFields,
        },
        blacklist_processes: {
          name: 'Processos na Blocklist',
          fields: processIdentifierFields,
        },
      },
    },
  },
]

const ExpansionPanelJudicialCriminalProcedures = ({ serviceInfos }) => {
  const renderServices = () => {
    return _.map(serviceInfos, (service) => {
      const info = _.find(infos, { serviceId: service.service_id })

      return (
        <CertificateRow
          key={service.service_id}
          title={service.name}
          service={service}
          otherInfos={info && _.get(service, `data`)}
          options={info && info.options}
        />
      )
    })
  }

  return (
    <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
      {renderServices()}
    </ul>
  )
}

export default ExpansionPanelJudicialCriminalProcedures
