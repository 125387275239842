import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { formatToCPF, formatToPhone } from 'brazilian-values'

import {
  Grid,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import { renderCheckService } from 'ui/components/ContainerCommonPanels/utilsFunctions'
import { TitleBold, Line, LineTitle, LineContent } from 'ui/components/ContainerCommonPanels/styledComponents'
import { columnsAddress, columnsVehicles } from './configDataTable'

import styles from './styles'

const ExpansionPanelCadastralData = ({ serviceInfos }) => {
  const classes = styles()
  const theme = useTheme()

  const person = _
    .chain(serviceInfos)
    .find({ service_id: 'search_infos_person_complete_v2' })
    .get('data.person')
    .value() || {}

  const situationCpf = _.find(serviceInfos, { service_id: 'cadastral_situation_cpf' })

  const renderObits = (service) => {
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    const data = _.get(service, 'data')

    const personDeath = _.get(person, 'death')

    if (
      data.cadastral_situation === 'TITULAR FALECIDO' ||
      (!_.isEmpty(personDeath) && !_.isEmpty(personDeath.death_date))
    ) {
      return <span style={{ color: theme.palette.primary.red }}>Sim</span>
    }

    return <span style={{ color: theme.palette.primary.green }}>Não</span>
  }

  const renderCpfCadastralSituation = (service) => {
    const data = _.get(service, 'data')

    const situation = _.get(data, 'cadastral_situation')
    const color = situation === 'REGULAR' ? theme.palette.primary.green : theme.palette.primary.red

    return situation && (
      <span style={{ marginLeft: 10, color: color }}>
        {situation}
      </span>
    )
  }

  const renderPisNumber = () => {
    const pisNum = person.pis_number

    return pisNum && (
      <Line>
        <LineTitle>PIS</LineTitle>
        <LineContent>{pisNum}</LineContent>
      </Line>
    )
  }

  const renderNames = (service) => {
    const socialName = _.get(service, 'data.social_name')
    const civilName = _.get(service, 'data.civil_name')

    return (
      <React.Fragment>
        {civilName && (
          <Line>
            <LineTitle>Nome Civil</LineTitle>
            <LineContent>
              <span>{civilName}</span>
            </LineContent>
          </Line>
        )}
        {socialName && (
          <Line>
            <LineTitle>Nome Social</LineTitle>
            <LineContent>
              <span>{socialName}</span>
            </LineContent>
          </Line>
        )}
      </React.Fragment>
    )
  }

  const renderPhones = () => {
    let personPhones = _.get(person, 'phones')
    if (_.isEmpty(personPhones)) return null

    personPhones = _.filter(personPhones, (val) => !_.isEmpty(val.phone))

    return (
      <Line>
        <LineTitle>Telefones Associados</LineTitle>
        <div>
          {personPhones.map(phone => {
            return (
              <LineContent key={phone.phone} style={{ width: 'initial', display: 'flex' }}>
                <span className={classes.phoneNumber}>
                  {formatToPhone(phone.phone)}
                </span>
                <span style={{ marginRight: 10, width: 20 }}>
                  {phone.whatsapp === true && (
                    <img
                      alt="WhatsApp logo"
                      src="/assets/images/others/whatsapp.svg"
                      style={{ width: 20 }}
                    />
                  )}
                </span>
                <span>
                  {phone.network}
                </span>
              </LineContent>
            )
          })}
        </div>
      </Line>
    )
  }

  const renderAddresses = () => {
    let addresses = _.get(person, 'addresses')
    if (_.isEmpty(addresses)) return null

    addresses = _.map(addresses, item => {
      const addressString = [
        item.street,
        item.number,
        item.complement,
        item.neighborhood,
      ].filter(Boolean).join(', ')
      const cityStateString = [item.city, item.state].filter(Boolean).join(', ')

      return {
        map: { address: addressString, zipcode: item.zipcode, cityState: cityStateString },
        address: addressString,
        neighborhood: item.neighborhood || '',
        city_state: cityStateString,
        zipcode: item.zipcode || '',
      }
    })

    return (
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Endereços</TitleBold>
        <DataTable
          data={addresses}
          columns={columnsAddress}
        />
      </div>
    )
  }

  const renderVehicles = () => {
    const vehicles = _.get(person, 'vehicles')
    if (_.isEmpty(vehicles)) return null

    return (
      <div>
        <TitleBold style={{ marginTop: '30px' }}>Veículos que possui ou possuiu</TitleBold>
        <DataTable
          data={vehicles}
          columns={columnsVehicles}
        />
      </div>
    )
  }

  return person && (
    <div>
      <TitleBold>{person.name}</TitleBold>
      <div style={{ marginTop: 30 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {renderNames(situationCpf)}
            {person.birth_date && (
              <Line>
                <LineTitle>Nascimento</LineTitle>
                <LineContent>
                  <span style={{ marginRight: '20px' }}>{person.birth_date}</span>
                  <span>
                    {`${moment().diff(moment(person.birth_date, 'DD-MM-YYYY'), 'years', false)} anos`}
                  </span>
                </LineContent>
              </Line>
            )}

            {person.gender && (
              <Line>
                <LineTitle>Gênero</LineTitle>
                <LineContent>{person.gender}</LineContent>
              </Line>
            )}

            {person.filiation && (
              <Line>
                <LineTitle>Nome da mãe</LineTitle>
                <LineContent>{person.filiation}</LineContent>
              </Line>
            )}

            {person.degree && (
              <Line>
                <LineTitle>Escolaridade</LineTitle>
                <LineContent>{person.degree}</LineContent>
              </Line>
            )}

            {person.cpf && (
              <Line>
                <LineTitle>CPF</LineTitle>
                <LineContent>
                  {formatToCPF(person.cpf)}
                  {renderCpfCadastralSituation(situationCpf)}
                </LineContent>
              </Line>
            )}

            <Line>
              <LineTitle>Óbito</LineTitle>
              <LineContent>{renderObits(situationCpf)}</LineContent>
            </Line>

            {person.ctps_number && (
              <Line>
                <LineTitle>Carteira de Trabalho Série</LineTitle>
                <LineContent>{person.ctps_number}</LineContent>
              </Line>
            )}

            {renderPisNumber()}

            {person.zodiac_sign && (
              <Line>
                <LineTitle>Signo</LineTitle>
                <LineContent>{person.zodiac_sign}</LineContent>
              </Line>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {renderPhones()}

            {person.procon && (
              <Line>
                <LineTitle>Procon</LineTitle>
                <LineContent>{person.procon}</LineContent>
              </Line>
            )}

            {person.emails && (
              <Line>
                <LineTitle>Email</LineTitle>
                <div>
                  {_.map(person.emails, (email, i) => {
                    return <LineContent key={i.toString()}>{email}</LineContent>
                  })}
                </div>
              </Line>
            )}
          </Grid>
        </Grid>
      </div>

      {renderAddresses()}
      {renderVehicles()}
      <br />
      <p>
        {' '}
        Para mais informações consulte a seção{' '}
        <strong>Histórico de Crédito</strong>
      </p>
    </div>
  )
}

export default ExpansionPanelCadastralData
