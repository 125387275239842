import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import CircularProgress from '@material-ui/core/CircularProgress'
import { Tabs, TitleView } from 'ui/components'
import Restrictions from 'ui/components/plan/Restrictions'

import { getOrganization } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'

import Container from './styles'

export default function () {
  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)
  const userOrgId = useSelector((state) => state.user.org_id)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  const [loading, setLoading] = useState(false)
  const [tabSelected, setTabSelected] = useState(0)
  const [organization, setOrganization] = useState(null)

  const tabsList = [
    { label: 'Restrições' },
  ]

  const getAndSetOrg = async (options = {}) => {
    let res = null

    try {
      res = await getOrganization(
        apiKey,
        userId,
        {
          orgId: options.orgId,
          withConfig: true,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao obter organização')
      return
    }

    setOrganization(res.data)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getAndSetOrg({ orgId: userOrgId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabChange = (index) => {
    setTabSelected(index)
  }

  if (loading) return <CircularProgress />

  return (
    <Container>
      <TitleView
        type="Configurações"
        title="Plano"
        subtitle="Exibe o plano"
      />

      <div className="main">
        <Tabs tabs={tabsList} onChange={handleTabChange} />
        <div className="tabs-content">
          {
            tabSelected === 0
            && <Restrictions docType={panelEnv} readOnly={_.get(organization, 'config.use_plan')} />
        }
        </div>
      </div>
    </Container>
  )
}
