import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { ThemeProvider } from 'styled-components'

import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import { Pagination, Table } from 'ui/components'
import { getDailyAnalytics } from 'services/apiMotorBgc'

import { serviceIdToName } from 'utils/functions'
import reportError from 'utils/errorReporter'
import { formatDate } from 'utils/masks'
import { PANEL_ENVS } from 'utils/constants/environments'

import { renderItemValue } from '../commonFuncs'
import Container from './styles'

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: 15,
    '&$expanded': {
      minHeight: 15,
    },
  },
  content: {
    margin: '5px 0',
    '&$expanded': {
      margin: '5px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export default function ({ _location }) {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const panelEnv = useSelector((state) => state.auth.panelEnv)

  let planServicesServices = null
  switch (panelEnv) {
    case PANEL_ENVS.CPF:
      planServicesServices = useSelector((state) => state.planCpfServices.services)
      break
    case PANEL_ENVS.CNPJ:
      planServicesServices = useSelector((state) => state.planCnpjServices.services)
      break
    default:
  }

  const [analyticsList, setAnalyticsList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = React.useState(false)
  const [expanded, setExpanded] = useState('panel1')

  const analyticsListDaily = async (options = {}) => {
    let res = null
    try {
      res = await getDailyAnalytics(
        apiKey,
        userId,
        panelEnv,
        {
          page: _.has(options, 'page') ? options.page : currentPage,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao listar analytics')
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setAnalyticsList(res.data)
    setLoading(false)
  }

  useEffect(() => {
    analyticsListDaily()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleChangePage = async (event, page) => {
    const options = {
      page: page,
    }

    setLoading(true)
    analyticsListDaily(options)
  }

  const renderAccordion = (id, title, content) => {
    return (
      <div>
        <Accordion square expanded={expanded === id} onChange={handleChange(id)}>
          <AccordionSummary>
            {title}
          </AccordionSummary>
          <AccordionDetails>
            {content}
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  const renderServiceDetail = (service) => {
    const { total } = service

    return (
      <tr>
        <td>{serviceIdToName(planServicesServices, service.service_id)}</td>
        <td>{total}</td>
        {/* <td>{renderItemValue(service.total_processed, total)}</td> */}
        {/* <td>{renderItemValue(service.total_processing, total)}</td> */}
        <td>{renderItemValue(service.total_approved, total)}</td>
        <td>{renderItemValue(service.total_reproved, total)}</td>
        <td>{renderItemValue(service.total_in_attention, total)}</td>
        <td>{renderItemValue(service.total_error, total)}</td>
      </tr>
    )
  }

  const renderServiceDetails = (services) => {
    return (
      <Table className="table">
        <thead>
          <tr>
            <th>Serviço</th>
            <th>Consultas</th>
            {/* <th>Processados</th> */}
            {/* <th>Processando</th> */}
            <th>Aprovados</th>
            <th>Reprovados</th>
            <th>Em Atenção</th>
            <th>Com Erros</th>
          </tr>
        </thead>

        <tbody>
          {_.map(services, (service, j) => (
            <React.Fragment key={j.toString()}>
              {renderServiceDetail(service)}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    )
  }

  const renderServicesItems = (list) => {
    return _.map(list, (item, i) => {
      const { services } = item

      return (
        <React.Fragment key={i.toString()}>
          {renderAccordion(
            item.initial_date,
            formatDate(item.initial_date, { hideTime: true }),
            _.isEmpty(services)
              ? 'Nenhum serviço'
              : renderServiceDetails(services),
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <ThemeProvider theme={{}}>
      <Container>
        <h1 className="title-container">Serviços</h1>

        {loading || renderServicesItems(analyticsList)}

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
        />
      </Container>
    </ThemeProvider>
  )
}
