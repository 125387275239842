import React from 'react'

import BaseConsultsList from 'ui/views/consults/BaseConsultsList'
import analysisStatusConstants from 'utils/constants/analysisStatus'

export default function ({ location }) {
  const title = 'Em Atenção'
  const subtitle = 'Consultas em atenção'
  const analysisStatus = analysisStatusConstants.SERVICE_ANALYSIS_STATUS_TYPE.in_attention
  const color = '#F4C109'

  const arrayAnalysisStatus = [
    analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.approved,
    analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.reproved,
    analysisStatusConstants.SERVICE_ANALYSIS_STATUS_MOVE_TO.blacklist,
  ]

  return (
    <BaseConsultsList
      location={location}
      title={title}
      subtitle={subtitle}
      color={color}
      analysisStatus={analysisStatus}
      actionsAnalysisStatus={arrayAnalysisStatus}
    />
  )
}
