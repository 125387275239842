import styled from 'styled-components'

export default styled.div`
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .tabs-content {
    margin-top: 20px;
  }
`
