import React from 'react'
import _ from 'lodash'

import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { renderCreditScore } from 'ui/components/ContainerCommonPanels/CreditScore'
import CreditPersonRestrictions from 'ui/components/ContainerCommonPanels/CreditPersonRestrictions'
import CreditRestrictions from 'ui/components/ContainerCommonPanels/CreditRestrictions'
import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import { renderCheckService } from 'ui/components/ContainerCommonPanels/utilsFunctions'

import {
  columnsAlerts,
  columnsBanking,
} from './configDataTable'


const ExpansionPanelCreditHistory = ({ serviceInfos, personService }) => {
  const theme = useTheme()

  const renderEquityAndIncome = (service) => {
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    const data = _.get(service, 'data')

    const dataIncome = _.get(data, 'patrimony_info.fx_renda')
    const dataEquity = _.get(data, 'patrimony_info.fx_patrimonio')

    return (
      <React.Fragment>
        <p>Renda: {dataIncome}</p>
        <p>Patrimônio: {dataEquity}</p>
      </React.Fragment>
    )
  }

  const renderAlertRestrictions = (person) => {
    if (_.size(person.alerts) === 0) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    return (
      <DataTable
        data={person.alerts}
        columns={columnsAlerts}
      />
    )
  }

  const renderBankRestrictions = (person) => {
    if (_.size(person.banking_restrictions) === 0) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    return (
      <DataTable
        data={person.banking_restrictions}
        columns={columnsBanking}
      />
    )
  }

  const renderPurchaseRestrictions = (person) => {
    if (_.size(person.shopkeepers_restrictions) === 0) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    return (
      <DataTable
        data={person.shopkeepers_restrictions}
        columns={columnsBanking}
      />
    )
  }

  const renderPostDatedChecks = (person) => {
    if (_.size(person.pre_dated_checks) === 0) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    return (
      <DataTable
        data={person.pre_dated_checks}
        columns={columnsAlerts}
      />
    )
  }

  const renderService = (service) => {
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    if (!_.get(service, 'data.consists')) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    return <strong style={{ color: theme.palette.primary.red }}>Sim</strong>
  }

  const renderGrid = (key, title, children) => {
    return (
      <Grid key={key} container spacing={2} style={{ marginBottom: 20 }}>
        <Grid item xs={3}>
          <div>{title}</div>
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: 20 }}>
          {children}
        </Grid>
      </Grid>
    )
  }

  const renderPersonInfos = (service) => {
    const elemCheck = renderCheckService(service, theme)
    const person = _.get(service, 'data.person')

    const title_and_functions_array = [
      { title: 'Restrições Bancárias', function: renderBankRestrictions },
      { title: 'Restrição de Cheques', function: renderPostDatedChecks },
      { title: 'Restrições de Compras', function: renderPurchaseRestrictions },
      { title: 'Alertas', function: renderAlertRestrictions },
    ]

    return (
      <React.Fragment>
        {_.map(title_and_functions_array, (object) => (
          renderGrid(
            object.title,
            object.title,
            elemCheck !== null ? elemCheck : object.function(person),
          )
        ))}
      </React.Fragment>
    )
  }

  const renderCreditPersonRestrictions = (service) => (
    <CreditPersonRestrictions service={service} />
  )

  const renderCreditScoreCpf = (service) => (
    renderCreditScore(service, theme)
  )

  const renderCreditRestrictions = (service) => (
    <CreditRestrictions service={service} />
  )

  const renderListServices = () => {
    const serviceIds = [
      'credit_person_restrictions',
      'credit_restrictions_cpf',
      'credit_score_cpf',
      'patr_calc_cpf',
    ]

    return _.map(serviceIds, (serviceId) => {
      const service = _.find(serviceInfos, { service_id: serviceId })
      if (_.isEmpty(service)) return null

      let functionRender = null
      switch (serviceId) {
        case 'credit_person_restrictions':
          functionRender = renderCreditPersonRestrictions
          break
        case 'patr_calc_cpf':
          functionRender = renderEquityAndIncome
          break
        case 'credit_score_cpf':
          functionRender = renderCreditScoreCpf
          break
        case 'credit_restrictions_cpf':
          functionRender = renderCreditRestrictions
          break
        default:
          functionRender = renderService
          break
      }

      return renderGrid(serviceId, service.name_short, functionRender(service))
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontSize: 16,
      }}
    >
      {renderListServices()}
      {renderPersonInfos(personService)}
    </div>
  )
}

export default ExpansionPanelCreditHistory
