import React from 'react'
import * as Sentry from '@sentry/browser'

export const columnsAlerts = [
  {
   name: 'date',
   label: 'Data',
   options: {
    filter: true,
    sort: true,
     customBodyRender: (value) => {
       try {
         return value ? <div style={{width: 100}}>{value}</div> : null
       } catch (err) {
         Sentry.captureException(err)
         return false
       }
     },
   },
  },
  {
   name: 'message',
   label: 'Mensagem',
  },
]

export const columnsBanking = [
  {
   name: 'bank',
   label: 'Banco',
  },
  {
   name: 'agency',
   label: 'Agência',
  },
  {
   name: 'competency_date',
   label: 'Competência',
  },
  {
   name: 'quantity',
   label: 'Quantidade',
  },
]
