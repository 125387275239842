import React from 'react'
import _ from 'lodash'

import CertificateRow from 'ui/components/ContainerCommonPanels/CertificateRow'

const infos = [
  {
    serviceId: 'ibama_assessment_cpf',
    options: {
      fields: {
        child_processes: {
          name: 'Processos',
          fields:{
            number: 'Nº da autuação',
            infraction_type: 'Tipo da infração',
            date: 'Data',
            state: 'Estado',
            city: 'Cidade',
            cpf_cnpj: 'CPF ou CNPJ',
            name: 'Nome',
            fine_value: 'Valor da multa',
            process_number: 'Numero do processo',
            debit_status: 'Status do débito',
            sanctions_applied: 'Sanções aplicadas',
          },
        },
      },
    },
  },
  {
    serviceId: 'ibama_embargo_cpf',
    options: {
      fields: {
        child_processes: {
          name: 'Processos',
          fields:{
            tad_serie: 'Nº Série',
            tad_number: 'Nº tad',
            area: 'Área',
            name: 'Nome ou Razão Social',
            cpf_cnpj: 'CPF ou CNPJ',
            uf: 'UF',
            city: 'Município',
            neighborhood: 'Bairro',
            address: 'Endereço',
            infringement_description: 'Infração',
          },
        },
      },
    },
  },
  {
    serviceId: 'ibama_negative_certificate_cpf',
    options: {
      fields: {
        child_processes: {
          name: 'Processos',
          fields: {
            number: 'Número do Processo',
          },
        },
      },
    },
  },
]

const ExpansionPanelIBAMA = ({ serviceInfos }) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
      {
        _.chain(serviceInfos)
        .sortBy(['name'])
        .map((service) => {
          const info = _.find(infos, { serviceId: service.service_id })
          return (
            <CertificateRow
              key={service.service_id}
              title={service.name}
              service={service}
              otherInfos={info && _.get(service, 'data')}
              options={info && info.options}
            />
          )
        }).value()
      }
    </ul>
  )
}

export default ExpansionPanelIBAMA
