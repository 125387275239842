import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Divider, Typography } from '@material-ui/core'
import { Button, Checkbox } from 'ui/components'

import { getOrganization, putOrganization } from 'services/apiMotorBgc'
import reportError from 'utils/errorReporter'
import GovBrAccountForm from 'ui/components/GovBrAccountForm'
import { formatGovBrAccountInput } from 'utils/functions'

export default function () {
  const formEditedInitialState = {
    usePlan: false,
    cpf: false,
    password: false,
    useGovBrAccount: false,
  }

  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)
  const userOrgId = useSelector((state) => state.user.org_id)

  const [organization, setOrganization] = useState(null)
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [hasFormError, setHasFormError] = useState(false)
  const [editedFormState, setEditedFormState] = useState(formEditedInitialState)

  const getAndSetOrg = async (options = {}) => {
    let res = null

    try {
      res = await getOrganization(
        apiKey,
        userId,
        {
          orgId: options.orgId,
          withConfig: true,
          withConfigGovBrAccount: true,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao obter organização')
      return
    }

    setOrganization(res.data)
  }

  useEffect(() => {
    getAndSetOrg({ orgId: userOrgId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async () => {
    const parseEditedOrgData = () => {
      const { config } = organization

      const newConfig = {}
      if (editedFormState.usePlan) newConfig.use_plan = config.use_plan
      if (editedFormState.useGovBrAccount) newConfig.use_gov_br_account = config.use_gov_br_account
      if (editedFormState.cpf || editedFormState.password) newConfig.gov_br_account = formatGovBrAccountInput(config.gov_br_account)

      return {
        config: newConfig,
      }
    }

    try {
      await putOrganization(
        apiKey,
        userId,
        {
          orgId: userOrgId,
          orgData: parseEditedOrgData(),
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao salvar')
      return
    }

    toast.success('Salvo com sucesso!')
    setEditedFormState(formEditedInitialState)
  }

  const handleCheckUsePlan = (checked) => {
    const newOrg = _.cloneDeep(organization)
    newOrg.config.use_plan = checked
    setOrganization(newOrg)
    setEditedFormState({ ...editedFormState, usePlan: true })
  }

  const renderOrgName = (orgName) => {
    return (
      <Typography>
        <span>Nome da Organização:</span>
        {' '}
        <strong>{orgName}</strong>
      </Typography>
    )
  }

  const renderOrgUsePlan = (orgUsePlan) => {
    return (
      <React.Fragment>
        <Typography>Plano</Typography>
        <Checkbox
          value={orgUsePlan}
          label="Aplicar o plano da organização para todos os usuários"
          onClick={handleCheckUsePlan}
        />
      </React.Fragment>
    )
  }

  const handleUpdateConfig = (newConfig) => {
    setOrganization({ ...organization, config: newConfig })
  }

  useEffect(() => {
    if (_.every(editedFormState, (v) => v === false)) {
      setSaveDisabled(true)
      return
    }
    if (editedFormState.cpf || editedFormState.password) {
      setSaveDisabled(hasFormError)
      return
    }

    setSaveDisabled(false)
  }, [hasFormError, editedFormState])

  const handleEditGovBrForm = (formName) => {
    setEditedFormState({ ...editedFormState, [formName]: true })
  }

  const renderGovBrForm = () => {
    return (
      <React.Fragment>
        <GovBrAccountForm
          config={organization?.config}
          onUpdateConfig={handleUpdateConfig}
          onUpdateHasError={(hasError) => setHasFormError(hasError)}
          onEditForm={handleEditGovBrForm}
          isOrganization={true}
        />

        <br />

        <Button disabled={saveDisabled} onClick={handleSave}>
          Salvar
        </Button>
      </React.Fragment>
    )
  }

  if (_.isEmpty(organization)) return null

  return (
    <div
      style={{
        display: 'flex',
        maxWidth: '40vw',
        flexDirection: 'column',
        gap: 20,
      }}
    >
      {renderOrgName(organization.name)}
      {renderOrgUsePlan(_.get(organization, 'config.use_plan'))}

      <Divider />

      {renderGovBrForm()}
    </div>
  )
}
