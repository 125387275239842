import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import {
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Checkbox } from 'ui/components'
import { MaskInput } from 'ui/components/MaskedInput'
import { cpfValid } from 'utils/validators'

import GovBrConfigInfo from './GovBrConfigInfo'

export default ({
  config,
  onUpdateConfig,
  onUpdateHasError,
  onEditForm,
  isOrganization = false,
}) => {
  const [userCpf, setUserCpf] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [hasCpfError, setHasCpfError] = useState(false)
  const [hasPasswordError, setHasPasswordError] = useState(true)

  useEffect(() => {
    const cpf = _.get(config, 'gov_br_account.user_cpf')
    setUserCpf(cpf)
    setHasCpfError(!cpfValid(cpf))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  useEffect(() => {
    onUpdateHasError(hasCpfError || hasPasswordError)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCpfError, hasPasswordError])

  const updateConfig = ({ newUserCpf, newUserPassword, useGovBrAccount }) => {
    const oldConfig = config
    const oldGovBrAccount = oldConfig?.gov_br_account || {}

    if (newUserPassword) {
      oldGovBrAccount.user_password = newUserPassword
    }
    if (newUserCpf) {
      oldGovBrAccount.user_cpf = newUserCpf
    }
    if (useGovBrAccount != null) {
      oldConfig.use_gov_br_account = useGovBrAccount
    }

    onUpdateConfig({ ...oldConfig, gov_br_account: oldGovBrAccount })
  }

  const validateCpf = (typedCpf) => {
    setHasCpfError(!cpfValid(typedCpf))
  }

  const validatePassword = (typedPass) => {
    setHasPasswordError(typedPass?.length < 8)
  }

  const handleChangeCpf = (e) => {
    const typed = e.target.value
    setUserCpf(typed)
    updateConfig({ newUserCpf:  typed })
    validateCpf(typed)
    onEditForm('cpf')
  }

  const handleChangePassword = (e) => {
    const typed = e.target.value
    setUserPassword(typed)
    updateConfig({ newUserPassword:  typed })

    onEditForm('password')
    validatePassword(typed)
  }

  const handleCheckUseGovBrAccount = (checked) => {
    updateConfig({ useGovBrAccount: checked })
    onEditForm('useGovBrAccount')
  }

  const renderUseOrgGovBrAccount = () => {
    if (isOrganization !== true) return null

    return (
      <Checkbox
        value={config.use_gov_br_account}
        label="Fazer uso da conta do GOV.BR da organização para todos os usuários"
        onClick={handleCheckUseGovBrAccount}
      />
    )
  }

  const renderInputCpf = () => {
    return (
      <MaskInput
        label="CPF do Usuário"
        name="cpf"
        value={userCpf}
        onChange={handleChangeCpf}
        errorMsg={hasCpfError && 'O CPF não é válido'}
        fullWidth
      />
    )
  }

  const renderInputPassword = () => {
    return (
      <TextField
        label="Senha do Usuário"
        value={userPassword}
        onChange={handleChangePassword}
        fullWidth
        error={!_.isEmpty(userPassword) && hasPasswordError}
        helperText={(!_.isEmpty(userPassword) && hasPasswordError) && 'A senha precisa ter no mínimo 8 caracteres'}
        type={passwordVisibility ? null : 'password'}
        required={true}
        autoCapitalize="off"
        autoComplete="new-password"
        autoCorrect="off"
        InputProps={{
          endAdornment: (
            <IconButton
              style={{ padding: 5 }}
              onClick={() => setPasswordVisibility(!passwordVisibility)}
            >
              {passwordVisibility ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
      />
    )
  }

  const renderWarningPasswordEncrypted = () => {
    return (
      <p style={{ fontSize: '1.3rem' }}>
        <strong>Observação:</strong>
        {' '}
        a senha digitada será criptografada e armazenada e não ficará mais vísivel nesta tela.
        {' '}
      </p>
    )
  }

  const renderGovBrConfigInfo = () => {
    return (
      <div style={{ marginTop: 15 }}>
        <GovBrConfigInfo />
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
      }}
    >
      <Typography>Conta do GOV.BR</Typography>

      {renderUseOrgGovBrAccount()}

      {renderInputCpf()}
      {renderInputPassword()}

      {renderWarningPasswordEncrypted()}

      {renderGovBrConfigInfo()}
    </div>
  )
}
